.shop-products-card {
    background-color: $white;
    margin-bottom: 1.5rem;
    border-radius: $radius;
    overflow: hidden;
    transition: all 0.25s ease;
    animation: fadeUp 0.75s ease both;

    &:hover {
        transform: translateY(-0.25rem);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.0125);
    }

    &-media {
        height: 130px;

        @supports (aspect-ratio: 16/9) {
            height: auto;

            img {
                aspect-ratio: 16/9
            }
        }

        &[style*=background] {
            padding-left: 1.125rem;
        }
    }

    &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: $silver;
    }

    &-icon{
        aspect-ratio: 16/9;
        width: 100%;
        height: 100%;
        padding: 1.25rem;
    }

    &-content {
        padding: 1.25rem;
    }

    &-title {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &-price {
        font-size: 1.125rem;
    }

    &-no-image{
        aspect-ratio: 16/9;
        background: white;
    }

    .button {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 1rem;
    }
}
