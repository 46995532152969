

body.scrolled{
    .cell-catalogue-list{
        
        // max-height: $heightScrolled;
    }
}

.cell-catalogue-list{
    transition: max-height 0.15s;
    overflow-y: auto;
    // min-height: $heightNormal;

    

    .product-entry{
        border: 1px solid transparent;
    }

    table{
        thead{
            th{
                padding:8px;
                cursor: pointer;

                i{
                    color: #ddd;

                    &.asc, &.desc{
                    
                        color: $gold;
                    
                    }
                    
                }

                
            }
        }

        tbody{

            

            td{
                padding-top:3px;
                padding-bottom: 3px;

                &.td-linked{
                    text-align: center;
                }
            }

            textarea, select, input, button { outline: none; }

      

            .wrap-product_name_funeral_home{
                font-size: 0.7rem;

                &.editing{
                    font-size: 0.7rem;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    background-color: pink;

                }
                
                
                &:before{
                    content: '(';
                }
                &:after{
                    content: ')';
                }
            
                
            }
        }
    }

}

.approved-row{
    background: antiquewhite;
}

.product-label{
    font-size: 0.7rem;
}