.options {
    &-nav {
        margin-bottom: 1.5rem;

        .button:not(.active) {
            @extend .button--ghost;
        }
    }

    &-table {
        border-spacing: 0;
        margin-bottom: 1.5rem;

        th {
            color: $light-blue;
            font-size: 0.675rem;
            padding: 0.5rem 0.25rem 0.5rem;
        }

        td {
            padding: 0.5rem 0.275rem;
        }

        th, td {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        tr:last-child td {
            border-bottom: none;
        }

        &-tight th,
        &-tight td {
            padding-bottom: 0;
        }

        &-small {
            width: 180px;
        }

        .button--reset i {
            color: $gold;
        }
    }

    &-form {
        .button:not(.button--reset) {
            margin-bottom: 0;
        }

        .button--ghost {
            color: $grey;

            &:hover,
            &:active,
            &:focus {
                color: $grey;
                background-color: rgba($grey, 0.1);
            }
        }

        &-heading {
            margin-bottom: 2rem;
        }

        &-preview {
            span {
                width: 300px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                font-size: 0.9rem;
                text-overflow: ellipsis;
            }

            .button--reset {
                color: $gold;
                font-family: $font-body;
                text-decoration: underline;

                &:hover,
                &:focus {
                    color: $blue;
                }
            }
        }

        .disabled-input{
            input[type="text"]{
                padding: 0;
            }
        }
    }
}

.align-center{
    display: flex;
    align-items: center;
}

.modal-header{
    height: 70px;
    background: #00427A;
    color: #FFFFFF;
    margin:-20px;
    display: flex;
    align-items: center;
    padding: 20px 30px 20px;
    font-weight: bold;
}

.modal-div{
    padding: 10px;
.button--ghost{
    color: $gold;
}
}

.error-message-div{
    position: absolute;
    top: 0;
    left: 0;
}

.align-end{
    display: flex;
    align-items: end;
}

.add-button-height{
    height: 46.6px;
}

.btn-close-options {
    position: absolute;
    top: 30px;
    right: 30px;
    color: white;
    background-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($charcoal, 0.5);
    }
  }