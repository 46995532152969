.home-image {
    display: flex;

    @include breakpoint(medium) {
        flex-direction: column;
    }
}

.home-existing-logo{
    border-radius: 5px;
    height:170px;
    border: 1px solid #979797;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    
    @include breakpoint(medium) {
        margin-right: 0;
        margin-bottom: 16px;
    }

    img{
        max-width: 90%;
        max-height: 90%;
    }

    &:hover{
        button{
            opacity: 1;
        }
    }

    button{
        border: 1px solid #979797;
        position: absolute;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        opacity: 0;
        cursor: pointer;

        i{
            margin-right:6px;
            
        }
    }

}

.dropzone-wrap{
    
    

    >div{
        border-color: #ccc !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height:170px;
        border-radius:5px;
        border-style: dashed;
        border-width: 2px;

        &.drag-active {
            background-color: #ddd;
        }

        i {
            width:50%;
            text-align: center;
            font-size: 2.5rem;
            margin-bottom: 1rem;
        }
        p {
            width: 65%;
            line-height: 1.2;
            margin-bottom: 0;
        }

    }
}

// body.scrolled{
//     .profile-panel-right{
//         max-height: $heightScrolled;
//     }
// }

.profile-panel-right{
    .cell-form{

        >p, >div{
            margin-top:12px;
            margin-bottom:12px;

            p{
                margin-top: 6px;
                margin-bottom: 6px;

                @include breakpoint(large) {
                    margin: 0;
                }
            }
        }

        input, select, textarea{
            margin: 0px;
        }

        input[type=submit], input[type=button] {
            background-color: $gold;
        }
    }

    .profile-form-button-lga {
        width: 100%;
        margin: 10px 0 0;
        padding: 0.875rem 1rem;

        @include breakpoint(large) {
            margin-top: 20px;
        }
    }
}

.table-contacts{
    border-collapse: collapse;

    &-wrap {
        overflow-x: auto;
    }

    thead{
        th{
            padding: 12px 8px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
    tbody{
        td{
            font-size: 0.9rem;
            padding:10px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        

        .td-icons{
            color: $gold;

            i{
                transition: transform 0.25s, color 0.25s;
                cursor: pointer;
    
                &:hover{
                    transform: scale(1.15);
                    color: darken($gold, 5%);
                }
            }

            >div{
                display: inline-block;
            }

            .td-editing-close{
                
                vertical-align: top;
            }

            .set-default,
            .set-role {
                margin-right: 0.5rem;
                font-size: 1.0125rem;
            }
        }

    }
}

.btn-add-contact{
    margin-top:20px;

}

.contact-password-reset {
    .modal-content-wrapper {
        padding: 20px;
    }
}
