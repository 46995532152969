.holder-diamond-editor{
    transition: max-height 0.15s;
    
    // overflow-y: auto;
    // min-height: $heightNormal;

}
body.scrolled{
    .holder-diamond-editor{
        height: $heightScrolled;
    }
    .cell-catalogue-side-panel{
        // min-height: $heightNormal;      
    }
    

}

.cell-catalogue-side-panel{
    // min-height: $heightNormal;      
 }
 

.price-points-config-wrap, .diamond-view-header{
    height:100px;
    overflow-y: hidden;
    
}

.price-point-entry{
    height: 38px;
    
}

.price-points-config-wrap{
    display:flex;
    flex-direction: row;
    
     

    label{
        width:50%;
    }
    input{
        width:50%;

    }
}

.price-point-entries{
    padding-top:110px;
}


.price-point-entry{
    margin:0px;

    position: relative;

    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;

    .price-from, .price-to{
        width:130px;
        padding:6px;
        background-color: #f9f9f9;

        &.price-to{
            text-align: center;
            border: none;
            border-radius: 4px;
            background-color: #eee;
            margin:0px;
            font-size: 0.85rem;

            &:before{
                content: '$';
            }
            
            &.editing{
                
                width:130px !important;
                
            }
        }
    }

    .separator{
        width: 17px;
        color: #ccc;
        font-size: 1rem;
        
        text-align: right;
        margin-right:4px;

    }

    .insert-after{
        transition: opacity 0.25s;
        color: $gold;
        position: absolute;

        display: block;
        
        top: -8px;
        font-size: 0.9rem;
        
        left:0px;
        right:0px;
        

        width:100%;
        height:14px;
        
        text-align: center;
        
        i{
            background-color: white;
            border: 1px solid $gold;
            border-radius: 200px;
            height:14px;
            width:14px;
            font-size: 0.6rem;
            position: absolute;
            left:50%;
            top:0px;
            margin-left:-10px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
        }

        &:after{
            content: '';
            display: block;
            position: absolute;
            top:50%;
            left:0px;
            right:0px;
            height:1px;
            background-color: $gold;

        }
        
        opacity: 0;

        &:hover{
            opacity: 1;
        }
    }

    .delete-line{
        width:20px;
        font-size: 0.85rem;
        i{
            transition: opacity 0.25s;
            color: #444;
            opacity: 0.2;

            &:hover{
                opacity: 1;
            }
        }
    }

    .div-edge-point-checkboxes{
        
        width:44px;
        display: flex;
        flex-direction: row;
        
        justify-content: flex-end;
        .chk-shape-edge{
            margin-right: 4px;
            color: #ddd;

            &.on{
                color: $gold;
                transform: scale(1.15);
            }

            &.chk-existing{
                color: lighten($red, 66%);

                &.on{
                    color: $red;                    
                }
            } 
            &.chk-proposed{
                color: lighten($green, 42%);

                &.on{
                    color: $green;
                }
            }

            
        }
    }


}

.diamond-view-header{
    text-align: center;

    h2{
        border: 1px solid transparent;
        border-radius: 5px;
    }
    

    overflow: hidden;
    .stat{
        font-size: 0.9rem;
        
    }

}
.cell-catalogue-list-pinning-true{
    .diamond-view-header{
        h2{
            cursor: pointer;
            &:hover{
                border: 1px solid $blue;
            }
        }
    }
}
.diamond-view{

    position: relative;

    .outline{
        pointer-events: none;
        
        position: absolute;
        top:0px;
        left:0px;
        right:0px;
        //bottom:0px;
        width:100%;
        height:calc(100%);
        z-index: 500;

        .poly-outline{
            fill:none;
            stroke:$red;
            stroke-width: 5;
            vector-effect: non-scaling-stroke;
        }
        .poly-outline-grid{
            fill:none;
            stroke:blue;
            stroke-width: 1;
        }
    }

    &.proposed{
        .outline{
            .poly-outline{
                stroke:$green;
            }
        }
    }
    
    .price-point-entry{
        background-image: linear-gradient(to right, #ddd 33%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 30px 1px; 
        background-repeat: repeat-x;
        
        width: 75%;
        margin:0px auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .product-entry{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 10px;
            flex-grow: 1;
            text-align: center;
            //background-color: #f1f1f1;
            padding: 5px;
            border-radius: 4px 5px;
            /*margin:0px 26px;*/
            max-width:20%;

            position: relative;
            z-index: 900;
            border:1px solid transparent;

            //background-color: rgba(#fff, 0.8);

            .product-pin{
                
                background-color: #555;
                
                color: white;
                border-radius: 200px;
                width:16px;
                height:16px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.7rem;
                flex-shrink: 0;

                margin-left: 2px;
                margin-top: -12px;
            
            }
        }
    }

}