.shop-footer {
    margin-top: 2rem;
    padding-top: 1.25rem;
    border-top: 1px solid $silver;
    position: relative;

    &--notice {
        @include breakpoint(small only) {
            margin-top: 4.25rem;
        }
    }

    &-notice {
        font-size: 0.825rem;
        margin: 1rem 0;
        line-height: 1.1;
        position: relative;
        padding-left: 1.25rem;
        
        @include breakpoint(small only) {
            left: 0;
            top: -3.5rem;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        @include breakpoint(medium) {
            margin: 0.5rem 1.25rem 0.5rem 0;
        }

        i {
            color: $orange;
            margin-right: 0.25rem;
            
            @include breakpoint(medium) {
                top: 50%;
                left: 0;
                position: absolute;
                transform: translateY(-50%);
            }
        }
    }

    .button:not(.button--reset) {
        margin: 0;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
