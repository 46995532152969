.shop-products-cats {
    margin-bottom: 2rem;

    .button {
        color: $charcoal;
        margin: 0 0.5rem 0.5rem 0;
        padding: 0.425rem 0.5rem;
        font-size: 0.85rem;
        border-width: 3px;
        border-style: solid;
        border-radius: $radius;
        border-color: currentColor;
        background-color: transparent;
        position: relative;

        &::before {
            content: "";
            opacity: 0;
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background-color: currentColor;
            transition: opacity 0.25s ease;
        }

        &:hover,
        &:focus,
        &[data-active="true"] {
            background-color: $charcoal;
            text-decoration: none !important;

            &::before {
                opacity: 1;
            }

            span {
                color: $white;
            }
        }

        &[style*=color] {
            border-left-width: 15px;
        }

        &:not([style*=color])[data-active="true"] {
            color: $gold;
        }

        span {
            position: relative;
        }
    }
}
