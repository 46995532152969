.proposal-body{

    
//    padding:50px;

    

}

.proposal-body-content{
    transition: height 0.15s;
    min-height: $heightNormal;
    background: #fcfcfc !important;
    background-image: url("../../images/bg-image.svg") !important;
    background-repeat: no-repeat !important;    
    background-size: contain !important;
}

.proposal-right-body-content{
    transition: height 0.15s;
    height: $heightNormal;
    background: #fcfcfc !important;
    background-image: url("../../images/bg-image.svg") !important;
    background-repeat: no-repeat !important;    
    background-size: contain !important;
    overflow: auto;
    padding: 2.5rem;
}

body.scrolled{
    .proposal-body-content{
        min-height: $heightScrolled;
    }
}

.button.archive{
    background-color: $gold;
    border-radius: 5px;
}
