.order-update {
  text-align: center;

  &-icon {
    font-size: 6rem;
    color: $gold;
    margin-bottom: 1.5rem;
  }

  &-title {
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  p {
    @include breakpoint(large) {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .button {
    margin-top: 1.25rem;
  }
}

.modal-create-new-order-title {
  border-bottom: 1px solid #363636;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0.8rem;
}

.select-funeral-home-dropdown{
        margin: 0.8rem 0;
}

.dropdown-title{
font-size: 19px;
font-weight: 350;
line-height: 29px;
letter-spacing: 0px;
text-align: left;
margin: 0.8rem 0;
}
.modal-confirm-fast-checkbox {
  font-size: 12px;
  letter-spacing: 0px;
  text-align: left;
}
