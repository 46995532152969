.dashboard-card {
  width: 320px;
  height: 270px !important;
  padding: 62px 20px 62px 20px;
  border-radius: 10px;
  background-color: #FFFFFF;

  .tile-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 900;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: center;
  }

  .tile-subtitle {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
  }
}

.dashboard-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.title-text {
  font-family: Avenir;
  font-size: 40px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: 0px;
  text-align: left;
}

.logout-button {
  font-family: Avenir;
  font-size: 19px;
  font-weight: 350;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  color: #b89653;
}
