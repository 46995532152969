.shop-products-added {
    &-icon {
        color: $gold;
        font-size: 5rem;
        margin-left: -2.5%;
    }

    &-title {
        margin: 1.5rem 0 2rem;
    }
}

.modal-product-added {
    .ReactModal__Content {
        padding: 2rem !important;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 360px !important;
    }

    .button {
        margin-bottom: 0;
    }
}
