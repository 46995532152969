.order {
    &-details,
    &-additional,
    &-product-search,
    &-delivery-details {
        margin-bottom: 2rem;
    }

    &-details-info {
        font-family: $font-body-bold;

        i {
            margin-right: 0.25rem;
        }

        span {
            display: flex;
            align-items: center;
            margin: 0 1.75rem 0.25rem 0;
        }
    }

    &-status {
        &[data-status=new] {
            color: $light-red;
        }
        
        &[data-status=processing] {
            color: $blue;
        }
    }

    &-updated {
        font-size: 0.9rem;

        @include breakpoint(large) {
            text-align: right;
        }
    }

    &-actions {
        margin-top: 2rem;

        @include breakpoint(small only) {
            text-align: center;

            .align-left,
            .align-right {
                justify-content: center;
            }

            .small-order-1 {
                margin-bottom: 1.75rem;
            }
        }

        button {
            margin-bottom: 0;
        }

        button + button {
            margin-left: 0.75rem;
        }
    }

    &-fast-track {
        margin-top: 1rem;
    }

    .product-table-actions .button {
        color: $gold;
    }
}

.modal-delivery-date {
    .modal-content-wrapper {
        padding: 0.5rem;
    }

    .order-date-heading {
        font-size: 1.25rem;
        font-family: $font-body-bold;
    }

    .order-date-actions {
        .button {
            margin: 0;
            width: 100%;
        }
    }

    .react-datepicker {
        border: none;
        color: $light-blue;
        margin: 0 -14px 12px;
        width: calc(100% + 28px);
        
        &__header {
            border-bottom: none;
            background: transparent;
            text-align: left;
        }

        &__navigation {
            background-color: $silver;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            border: none;
            text-indent: 0;

            &:hover,
            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba($charcoal, 0.5);
            }

            &::before {
                display: flex;
                height: 16px;
                justify-content: center;
                font-size: 0.625rem;
                align-items: center;
                color: darken($light-blue, 10%);

                @extend .fas;
            }

            &--previous {
                left: auto;
                right: 36px;

                &::before {
                    padding-right: 2px;
                    content: fa-content($fa-var-chevron-left);
                }
            }

            &--next {
                right: 12px;

                &::before {
                    padding-left: 2px;
                    content: fa-content($fa-var-chevron-right);
                }
            }
        }

        &__day-name,
        &__current-month {
            color: inherit;
            font-size: 0.825rem;
        }

        &__current-month {
            font-weight: inherit;
            margin: 5px 14px;
        }

        &__day,
        &__day-name {
            width: 2rem;
            line-height: 2rem;
            margin: 4px 9px;
        }
        
        &__day {
            color: inherit;
            border-radius: 50%;

            &--outside-month {
                opacity: 0.375;
            }
        }

        &__day-name {
            color: $gold;
        }
        
        &__month {
            margin: 0;
        }

        &__day--selected, 
        &__day--in-range,
        &__day--in-selecting-range {
            color: $white;
            background-color: $gold;
        }
    }
}
