.shop-products-item {
    min-height: 300px;

    &-media {
        padding-left: 1.25rem;
    }

    &-image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
        background-color: $silver;
        flex: 1 1 40%;

        @include breakpoint(medium) {
            object-position: 12.5% center;
        }
    }

    &-content {
        padding: 2.5rem;
        flex: 1 1 60%;
    }

    &-sku {
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }

    &-title {
        font-size: 1.25rem;
    }

    &-details {
        margin: 0.5rem 0 1rem;
    }

    &-attr {
        padding-right: 0.5rem;

        small {
            color: $light-blue;
        }

        p {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
        }
    }

    &-description {
        font-size: 0.85rem;
        margin-bottom: 1.25rem;
    
        ul {
            margin-bottom: 0;
            list-style-type: circle;
        }

        li:empty {
            display: none;
        }
    }

    // Form
    .button {
        margin-left: 1rem;
        width: calc(100% - 1rem);
    }

    &-submit {
        margin-top: auto;
        padding-top: 1.5rem;
        border-top: 1px solid $silver;

        input, 
        .button {
            margin-bottom: 0;
        }

        label {
            margin-right: 0.5rem;
            
            @include breakpoint(medium) {
                margin-right: 1rem;
            }
        }

        input {
            color: $gold;
            text-align: right;
            min-width: 0;

            @include breakpoint(small only) {
                &::-webkit-inner-spin-button, 
                &::-webkit-outer-spin-button { 
                    margin: 0;
                    -webkit-appearance: none; 
                }
            }
        }

        label, input {
            font-size: 1.25rem;
            font-family: $font-body-bold;
            
            @include breakpoint(medium) {
                font-size: 1.5rem;
            }
        }
    }
}

.modal-product {
    .ReactModal__Content {
        padding: 0 !important;
    }
}
