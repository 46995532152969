.cart-widget {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0.35rem 0.5rem;
    background-color: transparent;
    border: 3px solid $gold;
    font-size: 1.125rem;
    font-family: $font-body-bold;
    text-align: left;
    
    @include breakpoint(medium) {
        padding: 0.65rem 1rem;
    }

    &[data-items=true] {
        background-color: $gold;
    }

    &-icon {
        font-size: 1.25rem;
        margin-left: -0.25rem;
        padding-right: 0.75rem;

        @include breakpoint(medium) {
            font-size: 2.25rem;
        }
    }

    &-view {
        display: block;
        font-family: $font-body;
        text-decoration: underline;
    }
}
