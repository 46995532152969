.order-table {
    @include breakpoint(large) {
        border-spacing: 0 0.5rem;
    }
    
    tbody td {
        cursor: pointer;
        
        @include breakpoint(large) {
            border-top: 1px solid $silver;
            border-bottom: 1px solid $silver;
            
            &:first-child {
                border-left: 1px solid $silver;
            }
            
            &:last-child {
                border-right: 1px solid $silver;
            }
        }
    }

    tr[data-status=new] td {
        background-color: lighten($silver, 10%);
        border-width: 2px;

        @include breakpoint(large) {
            font-family: $font-body-bold;
        }
    }

    .button {
        border-width: 2px;
        border-radius: 3px;
    }

    &-home {
        display: block;
        padding-left: 100px;
    }

    &-image {
        width: 100px;
        height: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
        left: 0;
        padding: 5px;
        background-color: $white;
    }
}
