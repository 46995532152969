.shop-table {
  border-collapse: separate;
  border-spacing: 0 1rem;

  thead {
    margin-bottom: 20px;
    @include breakpoint(medium down) {
      display: none;
    }

    th {
      padding: 0 1rem;
      font-weight: 500;
      font-size: 12px;
      color: #2e5a81;

      @include breakpoint(large) {
        padding: 0 1.25rem;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  tbody td {
    padding: 0.75rem 1rem;
    background-color: $white;
    position: relative;

    @include breakpoint(large) {
      padding: 1rem 1.25rem;
    }

    @include breakpoint(medium down) {
      clear: both;
      display: block;
      text-align: left;
      width: 100%;

      &:empty {
        display: none;
      }
    }

    &[data-title]::before {
      display: block;
      margin-right: 1rem;
      font-family: $font-body-bold;
      content: attr(data-title) ": ";
      padding-bottom: 0.25rem;

      @include breakpoint(medium) {
        display: inline-block;
      }

      @include breakpoint(large) {
        display: none;
      }
    }

    &.shop-table-inline {
      &[data-title]::before {
        @include breakpoint(small only) {
          display: inline-block;
        }
      }
    }

    &:first-child {
      border-radius: $radius $radius 0 0;

      @include breakpoint(large) {
        border-radius: $radius 0 0 $radius;
      }
    }

    &:last-child {
      border-radius: 0 0 $radius $radius;

      @include breakpoint(large) {
        border-radius: 0 $radius $radius 0;
      }
    }

    strong {
      font-size: 1.125rem;
    }

    small {
      display: block;
    }

    .button {
      margin-bottom: 0;
    }
  }

  &-actions {
    text-align: right;
  }

  &-notice {
    @include breakpoint(large) {
      width: 40px;
      padding-left: 0 !important;
    }

    @include breakpoint(xxlarge) {
      width: 5px;
      padding: 0 !important;
    }

    i {
      color: $orange;

      @include breakpoint(xxlarge) {
        position: absolute;
        right: -2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.funural-home-text {
  font-size: 17px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #2e5a81;
}

.bold-text {
  font-weight: bold;
}

.table-header {
  margin-bottom: 20px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  .selected {
    background-color: #e6e6e6;
  }

  .disabled {
    padding: 0;
  }
}
