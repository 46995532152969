.filters {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .cell-search,
    .cell-filter,
    .cell-toggle {
        flex: 1 1 200px;
        padding-right: 1rem;
        margin-bottom: 0.5rem;

        label {
            display: block;
            font-size: 0.75rem;
            color: $light-blue;
        }

        input, select {
            margin-bottom: 0px;
        }

        &-wide {
            grid-column: span 2;
        }
    }

    .cell-filter-group {
        display: inline-block;

        button {
            margin-bottom: 0;
            
            & + button {
                margin-left: 0.5rem;
            }
        }

        &-label {
            display: inline-block;
            margin: 0 1rem 0.5rem 0;
        }
    }

    .cell-toggle {
        flex: 0 0 auto;
        align-self: center;
        
        @include breakpoint(large) {
            justify-self: end;
            padding-left: 1rem;
        }
    }

    .ul-filters{

        list-style: none;
        padding:0px;
        margin:0px;
        >li{
            display: inline-block;
            margin-right: 10px;
            font-size: 1.2rem;

            .a{
                color: $body;
                text-decoration: none;
                
                &.selected{
                    text-decoration: underline;
                }
            }

        }
    }

}

.funeral-home-type{
    margin-right: 1rem;
}

.funeral-home-filter-group{
    padding: 0 15px;
}

.align-center{
    align-items: center;
}

.active-button-color{
    background-color: #B89653;
    color: white;
}

.button--red {
    color: $orange;
    background-color: transparent;
    border: 2px solid $orange;

    &:hover,
    &-active,
    &:focus {
        color: #FFFFFF;
        background-color: $orange;
    }
}

.button--blue {
    color: #2E5A81;
    background-color: transparent;
    border: 2px solid #2E5A81;

    &:hover,
    &-active,
    &:focus {
        color: #FFFFFF !important;
        background-color: #2E5A81;
    }
}

.button--black {
    color: $charcoal;
    background-color: transparent;
    border: 2px solid $charcoal;

    &:hover,
    &-active,
    &:focus {
        color: #FFFFFF;
        background-color: $charcoal;
    }
}
