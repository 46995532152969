@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=e447c42f-b274-42e2-a647-e12a17c2e583');
@font-face {
    font-family: 'AvenirNextLTW01-Regular';
    src: url('../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix');
    src: url('../fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix') format('eot'),
        url('../fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2') format('woff2'),
        url('../fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff') format('woff'),
        url('../fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf') format('truetype');
}
@font-face {
    font-family: 'AvenirNextLTW01-Medium';
    src: url('../fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix');
    src: url('../fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix') format('eot'),
        url('../fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2') format('woff2'),
        url('../fonts/f26faddb-86cc-4477-a253-1e1287684336.woff') format('woff'),
        url('../fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf') format('truetype');
}
@font-face {
    font-family: 'Avenir Next LT W01 Demi';
    src: url('../fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix');
    src: url('../fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix') format('eot'),
        url('../fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2') format('woff2'),
        url('../fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff') format('woff'),
        url('../fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf') format('truetype');
}
$font-body: 'AvenirNextLTW01-Regular';
$font-body-med: 'AvenirNextLTW01-Medium';
$font-body-bold: 'Avenir Next LT W01 Demi';

@import '~foundation-sites/scss/foundation.scss';

@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-pro/scss/brands.scss';
@import '~@fortawesome/fontawesome-pro/scss/solid.scss';
@import '~@fortawesome/fontawesome-pro/scss/regular.scss';
@import '~@fortawesome/fontawesome-pro/scss/light.scss';

@import '~react-datepicker/dist/react-datepicker';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg';
@import '~react-tabs/style/react-tabs.scss';
@import '~react-datepicker/dist/react-datepicker';

@import '~draft-js-image-plugin/lib/plugin';
@import '~draft-js-alignment-plugin/lib/plugin';
@import '~draft-js-focus-plugin/lib/plugin';
@import '~draft-js-static-toolbar-plugin/lib/plugin';

@include foundation-global-styles;
@include foundation-xy-grid-classes;

@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-pagination;

@import 'vars';

@import 'animations';
@import 'base';
@import 'header';
@import 'nav';
@import 'filter';
@import 'homes';
@import 'login';
@import 'account';
@import 'options';

// Widgets
@import 'widgets/form';
@import 'widgets/menu';
@import 'widgets/cart';
@import 'widgets/tooltip';
@import 'widgets/chat';

// Prosals +
@import 'proposals/proposals';
@import 'proposals/home';
@import 'proposals/profile';
@import 'proposals/catalogue';
@import 'proposals/output';
@import 'proposals/diamond-editor';
@import 'proposals/diamond-editor-notes';
@import 'products/products';
@import 'funeral-homes/funeral-home';
@import 'funeral-homes/change-log';
@import 'funeral-homes/catalogue';
@import 'customer-mailouts/customer-mailouts';

// Shop views
@import 'shop/tables';
@import 'shop/products';
@import 'shop/review';
@import 'shop/components/header';
@import 'shop/components/footer';
@import 'shop/components/product';
@import 'shop/components/product-card';
@import 'shop/components/product-added';
@import 'shop/components/product-cart';
@import 'shop/components/product-table';
@import 'shop/components/product-options';
@import 'shop/components/fees-table';
@import 'shop/components/categories';
@import 'shop/components/address-form';
@import 'shop/components/confirm-fast-track';

// Order views
@import 'orders/orders';
@import 'orders/order';
@import 'orders/order-update';

// Customer views
@import 'customer/base';
@import 'customer/dashboard';
@import 'customer/account';
@import 'customer/order';
@import 'customer/orders';
@import 'customer/resources';

// Layout
@import 'layout/header';
@import 'layout/footer';

// Dashboard
@import 'dashboard/staff-dashboard';
