.customer-dashboard {
    &-heading {
        margin: 1rem auto 3.5rem;

        .button {
            text-decoration: underline;

            + .button {
                margin-left: 10px;
            }
        }

        .medium-4 {
            @include breakpoint(medium) {
                text-align: right;
            }
        }
    }

    &-tile {
        color: inherit;
        padding: 2.5rem 1.75rem;
        text-align: center;
        border-radius: $radius;
        margin-bottom: 1.75rem;
        background-color: $content_bg;
        transition: all 0.25s ease;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.025);

        @include breakpoint(medium) {
            padding: 4rem 3rem;
        }

        i {
            font-size: 4rem;
            margin-bottom: 2rem;
        }

        &-title {
            border: none !important;
        }

        &-content {
            line-height: 1.4;
        }

        &:hover {
            color: inherit;
            transform: translateY(-0.75rem);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.025);
        }

        &:hover,
        &:focus {
            color: lighten($charcoal, 10%);
        }
    }
}
