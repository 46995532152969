.modal-product-cart {
    &-title {
        font-size: 2.25rem;
        margin-bottom: 2rem;
    }

    &-actions {
        margin-top: 1rem;
        padding-top: 2rem;
        border-top: 1px solid $silver;

        .button {
            margin-left: 1rem;
        }
    }

    .modal-content-wrapper {
        @include breakpoint(large) {
            padding: 1rem 2rem 0.5rem !important;
        }
    }

    .ReactModal__Content {
        overflow: auto;
        .product-table tbody td {
            background-color: lighten($silver, 14%);
        }
    }
}
