.customer-order {
    &-back-top {
        font-weight: inherit;
    }

    &-heading {
        margin-bottom: 1.5rem;

        small {
            font-size: 0.9rem;
        }

        strong {
            display: block;
            line-height: 1.6;
            font-size: 1.125rem;
        }

        .cell {
            margin: 0.5rem 0;
        }
        
        .button {
            margin: 0;
            
            &--reset {
                font-family: inherit;
            }
        }
        
        &-details {
            line-height: 1.1;
            margin-top: 1.5rem;

            @include breakpoint(large) {
                margin-top: 0;
                justify-content: flex-end;
            }

            .cell {
                width: auto;
                
                &:not(:last-child) {
                    padding-right: 1.5rem;
                }
            }
        }
    }

    &-updated {
        font-size: 0.9rem;
    }

    &-additional {
        margin-bottom: 3.5rem;
    }
}
