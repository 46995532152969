.funeral-home-list, .customer-mailouts-list, .customer-mailout-audiences{

    padding:50px 0px;
    min-height:$heightNormal;
    transition: height 0.15s;

    body.scrolled & {
        height: $heightScrolled;
    }

    &.proposals-list{
        height:auto;


    }

    .cell-home{
        transition: box-shadow 0.25s, transform 0.25s;
        border: 1px solid #C5D0D9;
        border-radius: 10px;
        position: relative;

        color: $body;
        background-color: #EDF0F2;

        &.is-archived {
            opacity: 0.5;
        }

        a{
            color:$body;
        }

        &.cell-home-data{
            background-color: $gold;

            a{
                color: white;
            }
        }

        &.hide{
            display: none;

            &.show-archived {
                display: block !important;
            }
        }

        
        &:hover{
            box-shadow: 0 20px 30px -10px rgba(0,0,0,0.1);
            transform: translateY(-10px);
            
        } 

        .cell-home-inner{
            width:100%;
            padding-top:50%;
            height: 0px;

            &.cell-proposal-inner{
                padding-top: 100%;
            }
            &.cell-data-set-inner{
                padding-top: 100%;
            }
            

            .cell-home-inner-inner{
                position: absolute;
                top:0px;
                left:0px;
                right:0px;
                bottom:0px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                padding:10px;
                overflow: hidden;
                border-radius: 10px;

                .title{
                    text-transform: uppercase;
                    font-size: 1.1rem;
                    font-weight: bold;
                }

                i{
                    font-size: 2rem;
                }

                .created{
                    font-size: 0.8rem;
                }

                .props{
                    list-style: none;
                    margin:0px;
                    padding:0px;

                    >li{
                        margin:0px;
                        padding:0px;
                    }
                }

                .status{
                    background-color:#333;
                    color:white;
                    position: absolute;
                    top:-110px;
                    right:-110px;
                    width:190px;
                    height:190px;
                    transform: (rotate(45deg));
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    font-size: 0.8rem;
                    padding-bottom: 4px;
                }
            }
        }

    }  
}

.btn-add-home{
    margin-right:10px;
}
