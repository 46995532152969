.holder-prop-funeral-home-details{
    .profile-panel-left {
        background-color: transparent;
        
        @include breakpoint(medium) {
            max-width: 250px;
        }
    }
}

.grid-homes{
    button{
        color:#1779ba;
        cursor: pointer;
    }
}
