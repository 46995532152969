@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(0.75rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeUp {
    animation: fadeUp 0.5s ease both;
}
