.account-form {
    &-password {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.error-message{
    width: 100%;
    display: block;
    padding: 0.25rem;
    box-sizing: border-box;
    color: #fb6340;
    font-size: 80%;
}

.error-input{
    margin: 0;
}