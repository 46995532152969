.sign-in-out{
    a{
        color: white;
        text-decoration: underline;
    }

    .nav-home{
        margin-right:10px;
    }
}


.wrapper-cell-sign-in{
    
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.cell-sign-in{
    color: $body;
    box-shadow: 0 2px 5px 0 rgba(153,142,142,0.5), 0 0 50px -10px rgba(11,24,54,0.6);
    background-color: #FBFCFC;
    border-radius: 5px;
    max-width: 400px;
    padding: 40px 30px 30px;
    text-align: center;
    
    @include breakpoint(medium) {
        padding: 50px 60px 40px;
    }
    

    .logo{
        width:240px;
        margin:0 auto;
        
        margin-bottom: 40px;
    }

    form {
        margin-bottom: 20px;
    }

    input{
        background-color: #f1f1f1;
        border-radius: 5px;
        border: none;
        box-shadow: none;
        text-align: center;
        transition: all 0.25s ease;
        
        &::placeholder {
            color: lighten($body, 10%);
        }

        &:hover, 
        &:focus {
            border: none;
            background-color: #f5f5f5;
        }
    }

    button[type="submit"] {
        background-color: #F58220;
        color: white;
        width:100%;
        padding:10px 0px;
        border-radius: 5px;
        transition: all 0.25s ease;
        cursor: pointer;

        &:hover,
        &:focus {
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.125);
        }
    }

    a {
        color: $body;
        font-size: 0.9rem;
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:focus {
            opacity: 0.75;
        }
    }

    .error-login{
        margin-top:10px;
        font-size: 0.8rem;
        color: $body;
    }

}
