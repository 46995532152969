
.customer-mailout-audiences .cell-home .cell-home-inner .cell-home-inner-inner .title {
    font-size: 0.9rem !important;
}

.customer-mailout-audiences {

    .field-group {
        display: flex;
        input[type="text"]{
            margin-right: 10px;
            margin-bottom: 0;
        }
        input[type="submit"]{
            padding: 0 40px;
        }
    }
}

.DraftEditor-root {
    background: white;
    padding: 20px;
    border: 1px solid #cacaca;
    border-radius: 2px;
    margin-bottom: 10px;
}

.body-customer-mailouts{
    overflow: auto;
}

.stats {
    padding-top: 40px;
}

.mailout {
    .icon {
        position: absolute;
        bottom: 15px;
        right: 15px;
        i {
            font-size: 20px !important;
            color: $green;
        }
    }
}

#audience_select, #audience_state {
    position: relative;
    z-index: 10000;
}

button.audience-filter {
    background-color: rgba(#B89653, 0.8);
    padding: 0.35rem 1rem;
    margin-right: 0.5rem;
}

.top-stats {
    display: flex;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    .num {
        font-size: 24px;
        font-weight: 500;
    }
}

.right-pad {
    margin-right: 10px;
}

table.audience {
    margin-bottom: 20px;
    th,td {
        padding: 5px;
    }

    th {
        font-size: 18px;
        background: $green;
        color: white;
    }
    td {
        &:first-child {
            border-left: 1px solid #E2E8F3;
            border-right: 1px solid #E2E8F3;
        }
        &:last-child {
            border-right: 1px solid #E2E8F3;
        }
    }
}

