.shop-header {
    font-family: $font-body-bold;
    margin-bottom: 2.5rem;

    &-title {
        font-size: 2.25rem;
        margin-bottom: 0;
    }

    &-info {
        font-size: 1.1rem;
        font-family: inherit;
        
        &-icon {
            color: $orange;
        }
    }

    &-step {
        width: 2.75rem;
        height: 2.75rem;
        border-radius: 50%;
        color: $charcoal;
        font-size: 1.75rem;
        margin-left: 0.5rem;
        border: 2px solid $charcoal;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.25s ease;
        font-family: inherit;

        &:hover,
        &:focus {
            color: $charcoal;
            background-color: $pearl;
        }

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.25);
        }

        &-1 .shop-header-step:first-child,
        &-2 .shop-header-step:nth-child(-n+2),
        &-3 .shop-header-step:nth-child(-n+3) {
            color: $white;
            background-color: $charcoal;

            &:hover,
            &:focus {
                color: $white;
                background-color: $body;
            }

            &:focus {
                box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
            }
        }
    }

    .align-right {
        @include breakpoint(small only) {
            justify-content: flex-start;
        }
    }
}

.navigation{
    width: 100%;
    height: 83px;
}

.navigation-home-icon{
    margin-right: 6px;
    color: #000000;
    
    &:hover{
        color: #000000;
    }
}

.navigation-text-link{
    margin-right: 6px;
    color: #000000;
}

.navigator-text{
    font-size: 17px;
    font-weight: 900;
    color:  #363636;
}

.navigator-title{
    font-size: 40px;
    font-weight: 500;
    line-height: 55px;
    letter-spacing: 0px;
    color:  #363636;
}

.navigator-text:hover{
    border-bottom: 1px solid #000000;
    color: #000000;
}

.full-width{
    width: 100% !important;
}
