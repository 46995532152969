.bjm-input-wrap{
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 0px 10px;
    margin:10px 0px;
    width:100%;
    text-align: right;

    label{ 
        float:left;
        display: inline-block;
        color: #bbb;
        padding-top: 8px;
    }

    .react-datepicker-wrapper{
        input{
            display: inline-block;
            width:100px;
            margin-right:10px;
            
        }
    }

    i{
        
    }

    >div{
        display: inline-block;
    }

}



.bjm-inline-edit-wrap{
    margin-left:0px;

    .wrap-inline-edit{
        &.editing{
            margin-left:-1px;
        }
    }
}

.wrap-inline-edit{
    width:100%;
    display: block;
    margin: 4px 7px;
    outline:none;

    min-width: 50px;
    min-height: 20px;
    
    
    &.editing{      
         
        
        margin: 4px 6px;
        margin-top:5px;
        margin-bottom: 5px;

        font-size: inherit;
        border-radius: 4px;
        background-color: transparent;
        border:none;
        box-shadow: none;
        color: $gold;

        background-color: #f9f9f9;
        background-color: #eee;

        width:90% !important;

        &.text{
            margin-top:0px;
            padding-top:5px;
            margin-bottom: -3px;
            padding-bottom: 4px;
            margin-left:1px;
            padding-left: 6px;
        }

        &.number{
            margin-left:-1px;
        }
     
    }
    
}

input[type=submit], input[type=button]{
    background-color: $gold;
    color: white;
    width: 200px;
    max-width: 100%;
    padding: 10px 0px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.form {
    &-section {
        margin-bottom: 2rem;
    }

    &-scroll-x {
        overflow-x: auto;
    }

    .grid-x .cell-form > p {
        margin-top: 0;
    }

    label {
        color: $light-blue;
        font-size: 0.75rem;
    }
}
