.product-options {
    padding-top: 1.25rem;
    margin-bottom: 1.25rem;
    border-top: 1px solid $silver;

    small,
    .product-options-input label {
        font-size: 0.875rem;
        color: $light-blue;
    }

    &-type {
        margin-bottom: 0.5rem;

        input {
            margin: 0 0.25rem 0 0;
            transform: translateY(1px);
        }

        label {
            padding-right: 1rem;
        }
    }

    &-select {
        position: relative;

        &-notice {
            color: $orange;
            position: absolute;
            right: -1.5rem;
            bottom: 2rem;
            font-size: 1.125rem;
        }
    }

    &-input-wrap {
        position: relative;

        input {
            margin-bottom: 0.5rem;
            -webkit-appearance: none; 
            -moz-appearance: textfield;
            appearance: textfield;

            &::-webkit-inner-spin-button, 
            &::-webkit-outer-spin-button { 
                margin: 0; 
                -webkit-appearance: none; 
            }

            + .suffix {
                position: absolute;
                right: 1rem;
                bottom: 0.75rem;
            }
        }
    }

    &-convertion {
        p {
            margin: 0;
        }
    }

    &-notice {
        margin: 1rem auto 0;
        font-size: 0.825rem;

        i {
            color: $orange;
            margin-right: 0.25rem;
        }
    }
}
