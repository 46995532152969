.footer {
    background-color: #00427a;
    height: 100px;

    &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .footer-items-listings {
        ul {
            display: flex;
            list-style: none;
            align-items: center;
            margin: 0;
            li {
                padding: 10px;
                margin: 0;

                a {
                    text-decoration: none;
                    color: white;
                    font-weight: bold;
                }

                .active {
                    color: #b89653;
                }
            }
        }
    }
}
