$blue: #00386C;
$light-blue: #6784a5;
$body: #4A4A4A;
$content_bg: #FBFCFC;
$gold: #B89653;

$red: #94021d;
$light-red: #da2a28;
$green: #66805c;
$orange: #E65A25;
$charcoal: #363636;
$grey: #6e6e6e;
$cream: #f0edea;
$pearl: #eae4dc;
$silver: #D8D8D8;

$heightNormal: calc(100vh - 200px);
$heightScrolled: calc(100vh - 144px);

$radius: 5px;

@keyframes blinker {
    50% {
      opacity: 0;
    }
}
