.modal-confirm-fast-track {
    .button {
      margin-bottom: 0;
    }
  }
  
  .modal-confirm-fast-track-title{
      border-bottom: 1px solid #363636;
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0.5rem;
  }
  
  .confirm-section{
      display: flex;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
  
      input[type="checkbox"] {
          margin-top: 0.15rem;
          min-width: 18px;
          height: 20px;
        }
  }
  
  .modal-confirm-fast-checkbox {
    font-size: 12px;
    letter-spacing: 0px;
    text-align: left;
  }
  