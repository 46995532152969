.left-nav{
    width:64px;
    padding-left:3px;
    background: #00427A;
    
    ul{
        margin:0px;
        padding:0px;
        list-style: none;
        li{
            margin:0px;
            padding:0px;
            

            

            a{

                width:61px;
                height:60px;
                display: flex;
                align-items: center;
                justify-content: center;

                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                
                position: relative;

                i{
                    color: white;
                    font-size: 1.3rem;
                }

                &.active{

                    background-color: $content_bg;


                    i{
                        color: $gold;
                    }



                }
            }


            
        }
    }
}