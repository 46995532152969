.__react_component_tooltip{
    
    font-size: 0.75rem;
    text-align: center;

    p{
        margin:0px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}
