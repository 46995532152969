.resources-label {
  font-size: 17px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  border-bottom: 1px solid #363636;
  margin-top: 40px;
  margin-bottom: 16px;
  padding-bottom: 4px;
}

.resource-image {
  min-height: auto;
}

.resource-title {
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
}

.resource-label {
  font-size: 15px;
  font-weight: 350;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #b89653;
}

.resource-div {
  cursor: pointer;
}
