.menu-widget {
    .button {
        margin-bottom: 0;

        i {
            margin: 0 0 0 0.5rem;
        }
    }
    
    .button--reset {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
