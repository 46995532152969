.proposal-overview{
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    >div{
        margin:10px 0px;
        
        i{
            display: inline-block;
            margin-right:10px;
            vertical-align: top;
            margin-top:3px; 
            
        } 
        
        div{ 
            display: inline-block;
            vertical-align: top;
        }
    }
}

.cell-output-side-panel{
    // min-height: $heightNormal;
    overflow: auto;
}
body.scrolled{
    .cell-output-side-panel{
        min-height: $heightScrolled;
    }
}


.cell-output-list{
    transition: max-height 0.15s;
    // overflow-y: scroll;
    // max-height: $heightNormal;

    table{
        i{
            cursor: pointer;
            color: #555;
            margin-left:8px;
            
        }

        .chk-output{
            //margin-right: 4px;
            i{
                color: #ddd;
            }
            &.on{
                i{
                    color: $gold;
                }
                transform: scale(1.15);
            }
        }

        td{
            padding: 10px 0px;
        }
    }

    
}
body.scrolled{
    .cell-output-list{
        max-height: $heightScrolled;
    }
}

input.btn-download{
    margin-top:5px;
    width:100%;
}

.output-download-link{
    
    iframe{
        position: fixed;
        top:20px;
        left:20px;
        right:20px;
        bottom:20px;
        width:calc(100% - 40px);
        height:calc(100% - 40px);
        z-index: 500;
    }
}

.a-hide-output{
    position: fixed;
    top: 31px;
    right: 44px;
    z-index:600;

    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    color:white;
    background-color: rgba(black, 0.4);

    
    text-decoration: none !important;

    i{
        color: white !important;
        margin-left:0px !important;

    }
    
}

#canvas{
    visibility: hidden;
}
