.shop-review {
    &-form {
        h2 {
            margin-top: 2.5rem;

            @include breakpoint(large) {
                margin-top: 4rem;
            }
        }
    }

    &-address {
        &-state {
            text-transform: uppercase;
        }
        
        .button {
            margin-bottom: 0;
        }
    }

    &-fast-track-icon {
        font-size: 0.9em;
        margin-left: 0.25rem;
    }
}

.fast-track-text{
    font-size: 18px;
    line-height: 10px;
}

.fast-track-additional-charge-text{
    font-size: 10px;
    margin-left: 0.25rem;
}

.address-title-text{
    width: 80%;
}

.accessory-categories{
    padding: 0.5rem !important;
    margin-right: 12px;
}

.active-button-color{
    border: 2px solid $gold;
}