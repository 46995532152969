body {
    font-family: $font-body;
    background: linear-gradient(225deg, #365475 0%, #5181b5 100%);
    background-image: url(../images/admin-login-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    color: white;
    overflow: hidden;
    min-height: 100vh;
}

.ug{
    width: 100vw;
    height: 100vh;
}

.scroll-div{
    height: calc(100% - 100px);
    min-height: $heightNormal;
    overflow: auto;
}

.ug + div:empty {
    display: none !important;
}

h2 {
    font-size: 1.3rem;
}

.heading-underline {
    color: #363636;
    font-family: $font-body-bold;
    margin-bottom: 1.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 1px solid $silver;
}

.text-large {
    font-size: 1.05rem;
    line-height: 1.25;
    font-family: $font-body-med;
}

.body-white {
    color: $body;
    height: 100%;
}

#page-logo img {
    width: 140px;
    transition: width 0.25s ease;
}

.page-content {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-y: auto;
    position: relative;
    transition: max-height 0.15s;
    // min-height: $heightNormal;
    // max-height: $heightNormal;

    .scrolled & {
        max-height: $heightScrolled;
    }
}

button.a {
    text-decoration: underline;
    color: white;
    cursor: pointer;

    &.black {
        color: $body;
    }
}

#root {
    width: 100%;
}

table {
    color: $body;
    width: 100%;
    max-width: 75rem;

    thead {
        th {
            font-size: 0.8rem;
            text-align: left;
            padding-bottom: 30px;
        }
    }
    tbody {
        td {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
}

.grid-container {
    position: relative;
}

.grid-x {
    min-width: 100%;
}

.global-loading {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.5);
    z-index: 100;

    img {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }
}

.cell-side-panel {
    width: 100%;

    @include breakpoint(medium) {
        width: 270px;
    }

    h2 {
        margin-top: 30px;
        font-size: 1.15rem;
    }

    form {
        p {
            margin-bottom: 12px;
        }
        .react-select-container {
            margin-bottom: 12px;

            > div {
                background-color: #f9f9f9;
                border: none;
                box-shadow: none;
            }
        }

        input,
        select,
        textarea {
            background-color: #f9f9f9;
            border: none;
            box-shadow: none;
            border-radius: 4px;
            margin: 0px;
            font-size: 0.85rem;

            &:focus {
                border: none;
            }
        }

        input[type='submit'] {
            background-color: $gold;
            color: white;
            width: 100%;
            padding: 10px 0px;
        }
    }
}
.cell-main-panel {
    padding: 0 12px 12px 56px;
}

.error,
.success,
.info {
    line-height: 1.4;
    padding: 8px 10px;
    border: 1px solid;
    border-radius: 2px;
}

.error {
    border-color: $red;
}

.success {
    border-color: $green;
}

.info {
    border-color: $blue;
}

.readonly {
    opacity: 0.5;
}

.cell-404 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.display-error {
    background-color: white;
    color: $body;
    padding: 30px;

    h2 {
        margin-bottom: 20px;
    }
    a {
        color: $body;
        font-weight: bold;
    }
}

.warning {
    border: 1px solid $red;
    padding: 10px;
    color: $red;
}

// Utilities
.flex-wrap {
    flex-wrap: wrap;
}

.capitalize {
    text-transform: capitalize;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

[data-hidden='true'] {
    opacity: 0;
    visibility: hidden;
}

// Forms
input:not([type='submit']),
textarea,
select {
    padding: 0.65rem 0.75rem;
    border: 1px solid $silver;
    border-radius: $radius;
    background-color: $white;
    box-shadow: none;
    height: auto;

    &::placeholder {
        color: $grey;
    }

    &:hover {
        box-shadow: inset 0 0 0 1px rgba($charcoal, 0.075);
    }

    &:focus {
        border-color: $silver;
        box-shadow: inset 0 0 0 2px rgba($charcoal, 0.125);
    }
}

select,
input:not([type='submit']) {
    height: auto;
}

select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='%232c3e50' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpolyline points='6 9 12 15 18 9' /%3E%3C/svg%3E");
    background-position: calc(100% + 1.5rem) center;
    background-size: 20px;
    padding-right: 2rem;
}

input[type='search'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-search' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='%232c3e50' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Ccircle cx='10' cy='10' r='7' /%3E%3Cline x1='21' y1='21' x2='15' y2='15' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 20px;
    padding-right: 40px;

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }
}

.button {
    font-family: $font-body-bold;
    background-color: $gold;
    color: white;
    border-radius: 5px;
    cursor: pointer;

    &:focus {
        outline: none;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
    }

    &:hover,
    &:focus,
    &:active {
        background-color: lighten($gold, 5%);
    }

    &--reset {
        margin: 0;
        padding: 0;
        color: inherit;
        background-color: transparent;
        font-family: $font-body-bold;

        i {
            margin-right: 0.25rem;
        }

        &:hover,
        &:active,
        &:focus {
            color: inherit;
            text-decoration: underline;
            background-color: transparent;
        }
    }

    &--ghost {
        color: $gold;
        background-color: transparent;
        border: 2px solid currentColor;

        &:hover,
        &:active,
        &:focus {
            color: $gold;
            background-color: rgba($gold, 0.1);
        }
    }

    i {
        margin-right: 0.25rem;
    }

    button {
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }
}

.fancy-checkbox {
    position: relative;
    padding-left: 22px;
    cursor: pointer;

    @extend %fa-icon;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        line-height: 1;
    }

    small {
        font-size: 0.85rem;
        line-height: 1.3;
        display: inline-block;
    }

    input,
    label::before,
    label::after {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 0.25s ease;
    }

    input {
        margin: 0;
        opacity: 0;

        &:checked + {
            label::before {
                opacity: 1;
            }

            label::after {
                background-color: $gold;
            }
        }
    }

    label {
        margin-top: -3px;

        &::before {
            content: fa-content($fa-var-check);
            opacity: 0;
            padding: 3px;
            color: $content_bg;
            font-size: 13px;
            z-index: 1;

            @extend .fas;
        }

        &::after {
            content: '';
            border: 2px solid $gold;
            border-radius: 2px;
        }
    }

    &--large {
        padding-left: 34px;

        input,
        label::before,
        label::after {
            width: 35px;
            height: 35px;
        }

        label {
            margin-top: 2px;

            &::before {
                font-size: 1.75rem;
            }

            &::after {
                border-radius: $radius;
            }
        }
    }
}

.pagination {
    margin: 1.5rem 0;

    a {
        border-radius: $radius;
        transition: all 0.25s ease;

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 1px rgba($black, 0.25);
        }
    }

    .current {
        padding: 0;
        background-color: transparent;

        a {
            color: $white;
            background-color: $charcoal;

            &:focus {
                box-shadow: inset 0 0 0 1px rgba($white, 0.5);
            }
        }
    }
}

// Modals
.ReactModal__Overlay {
    opacity: 0;
    z-index: 2;
    overflow-x: auto;
    transition: opacity 0.125s ease-in-out;
    backdrop-filter: blur(3px);
    background-color: rgba($charcoal, 0.575) !important;

    &--after-open {
        opacity: 1;
    }

    &--before-close {
        opacity: 0;
    }
}

.modal-content {
    &-wrapper {
        width: 100%;
    }
}

button:disabled,
button[disabled],
input[type="submit"]:disabled,
input[type="submit"][disabled]{
  background-color: #eee !important;
  color: #0a0a0a !important;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.align-items-end{
    display: flex;
    align-items: flex-end;
}

.product-selection-checkbox{
    margin-left: 0.5rem;
}

.navigator-heading-font-size{
    font-size: 32px !important;
}