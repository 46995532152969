.header{
    transition: height 0.15s;
    height: 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &-left {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }

    .breadcrumbs{
        font-size: 0.9rem;
        padding-left: 2px;

        a{
            color: white;

            &:hover{
                text-decoration: underline;
            }
        }
    }
 

    h1{
        
        font-size: 2.25rem;
        line-height: 1;
        margin:0px;

        a{
            color: white;
        }
        
    }

    .a-h1-nav{
        color: white;
        font-size: 1.1rem;
        margin-left:10px;
    }
}

.header-icons{
    position: absolute;
    bottom: 4px;
    right: 14px;
    font-size: 1.125rem;
    cursor: pointer;

    i{
        margin-left:20px;

        &:hover{
            transform: scale(1.05);
        }
    }
}

body.scrolled{
    .header {
        height:44px;
        
        &-left {
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
        }

        h1 {
            order:1;
            margin-right: 10px;
            font-size: 1.25rem;
        }
    }
    
    .breadcrumbs{
        order: 2;
        font-size: 0.75rem;
    }

    .header-icons {
        display: none;
    }

    #page-logo img {
        width: 60px;
    }
}
