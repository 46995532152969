// Base styles for customers (and now managers), they have a different "theme" then admins.
.customer,
.manager {
    overflow: initial;
    background: $charcoal;

    .header {
        color: $content_bg;
    }

    .page-content {
        overflow-y: initial;
        max-height: 100%;
    }

    .footer {
        @include breakpoint(small only) {
            text-align: center;

            .flex-container {
                justify-content: center;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-body-bold;
        line-height: 1.2;
    }

    main {
        color: $charcoal;
        background-color: $cream;
        position: relative;
        overflow: auto;

        h1 {
            font-size: 2.45rem;
        }

        h2 {
            padding-bottom: 0.25rem;

            small {
                opacity: 0.75;
                color: inherit;
                font-size: 0.75rem;
                margin-left: 0.25rem;

                &::before {
                    content: '(';
                }

                &::after {
                    content: ')';
                }
            }
        }

        strong {
            font-family: $font-body-bold;
        }
    }

    // Forms
    form {
        label {
            color: inherit;
            font-family: $font-body-med;
        }
    }

    .dropzone-wrap > div {
        border-color: $charcoal !important;
    }

    .fancy-checkbox {
        label::after {
            border-color: $gold;
        }
    
        input:checked + label::after {
            background-color: $gold;
        }
    }

    // Tables
    table tbody td {
        border: none;
    }

    .options-table th {
        color: inherit;
    }
}

.black-color{
    color: #000000;
}