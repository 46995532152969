.shop-products {
    &-list {
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;
    }

    &-search {
        position: relative;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &-catalogue {
        padding: 2rem;
        background-color: lighten($cream, 2.5%);
        border-radius: $radius;

        .shop-products-cats .button {
            font-size: 0.775rem;
        }
    }
}
