.product-table {
  &-thumb {
    width: 150px;
    min-height: 32.5vw;
    position: relative;
    overflow: hidden;

    @include breakpoint(large) {
      min-height: auto;
    }
  }

  &-media {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &[style*="background"] {
      padding-left: 1.125rem;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 12% center;
    background-color: $silver;
  }

  &-name {
    margin: 0.25rem 0;
    font-size: 1.125rem;
  }

  &-description {
    font-size: 0.85rem;

    ul {
      margin-bottom: 0;
      list-style-type: circle;
    }

    li:empty {
      display: none;
    }
  }

  &-dimensions {
    width: 110px;
    font-size: 0.85rem;

    ul {
      margin: 0;
      list-style-type: none;
    }
  }

  &-quantity {
    font-size: 1.5rem;
    text-align: center;
    font-family: $font-body-bold;

    @include breakpoint(small only) {
      &[data-title]::before {
        float: left;
        padding-right: 0.5rem;
      }
    }
  }
  &-higgin-name {
    font-size: 10px;
    font-weight: 350;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }

  &-actions {
    width: 100px;

    button.button {
      padding: 0.25rem;

      &:not(:first-child) {
        margin-left: 0.5rem;
      }

      i {
        margin-right: 0;
      }

      &-edit {
        color: $gold;

        &:hover,
        &:focus {
          color: darken($gold, 10%);
        }
      }

      &-remove {
        font-size: 1.025em;

        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
  }
}
