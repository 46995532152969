.customer-orders {
    &-current,
    &-completed {
        margin-top: 2.5rem;
    }
}

.customer-order-table {
    tbody td {
        min-width: 75px;

        @include breakpoint(large) {
            padding: 1.75rem 1.5rem;
        }
    }
}
