.fees-table {
    border-spacing: 0 0.5rem;
    margin-bottom: 2rem;

    tbody td {
        padding: 0.5rem 0.75rem;
    }

    &-qty {
        font-weight: bold;

        @include breakpoint(large) {
            text-align: center;
        }
    }
}
