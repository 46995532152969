.chat-widget-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    background-color: #b89653;
    border-radius: 5px;
    cursor: pointer;

    h6 {
        font-family: 'Avenir Next LT W01 Demi';
        font-size: 16px;
        margin: 0;
    }

    i {
        margin: 0 0 0 0.5rem;
    }
}
