.product-list {
  padding: 0px;
  margin: 0px;

  .cell {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6px;

    > a {
      display: flex;
      flex-direction: row;
      color: $body;
    }

    .product-name {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .product-img {
      width: 140px;
      height: 100px;
      margin-right: 10px;
      object-fit: cover;

      &.product-img-empty {
        background-color: #eee;
      }
    }
  }
}

.product-detail {
  padding-top: 50px;
  padding-bottom: 50px;
}

.products-body,
.product-body {
  &.products-body {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  transition: max-height 0.15s;
  overflow-y: auto;
  min-height: $heightNormal;
}

body.scrolled {
  .products-body,
  .product-body {
    min-height: $heightScrolled;
  }
}

.category-filter {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  .cell-filter {
    flex: 1 1 200px;
    margin: 0 16px;
  }
}

.filter {
  height: 47.27px;
}

.details-section {
  margin-bottom: 20px;
}

.id-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.custom-text {
  font-size: 17px;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 0px;
  color: #2e5a81;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.original-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.product-details-title {
  font-size: 17px;
  font-weight: 900;
  margin-bottom: 24px;
  border-bottom: 1px solid #363636;
}

.div-division {
  display: flex;
}

.left-side-column {
  width: 50%;
  box-sizing: border-box;
  padding-right: 20px;
}

.right-side-column {
  width: 50%;
}

.disabled-input {
  input[type="text"],
  input[type="number"] {
    background: none;
    border: none;
    padding-left: 0;
    font-weight: bold;
    pointer-events: none;
  }
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.cancel-button {
  margin-left: 10px;
  font-weight: bold;
}

.align-left{
  text-align: left;
}

.align-right{
  text-align: right;
}
