.customer-account {
    .home-existing-logo {
        background-color: $white;
        border-color: $silver;
    }

    .cell-side-panel {
        padding-left: 0;
        padding-right: 0;
    }

    .profile-form {
        select {
            border: 1px solid $silver;
            background-color: $white;
        }

        > .grid-x > .large-6 {
            @include breakpoint(large) {
                width: calc(100% - 1.875rem);
            }
        }
    }

    .profile-panel-left {
        @include breakpoint(medium) {
            // padding-top: 3.95rem;
        }
    }
}
