.cell-diamond-notes{
    transition: max-height 0.15s;
    // overflow-y: auto;   
    // min-height:$heightNormal;

    &.cell-catalogue-list-pinning-true{
        .product-entry{
            cursor: pointer;
            z-index: 900;
            &:hover{
                border-color: $blue;
                background-color: white;
                
            }
        }
    }
}
body.scrolled{
    .cell-diamond-notes{
        max-height: $heightScrolled;
    }
}


.note-entry{
    border: 1px solid #D9DEEB;
    border-bottom: none;

    display: flex;
    font-size: 0.9rem;


    &:last-child{
        border-bottom: 1px solid #D9DEEB;
    }

    &.note-entry-new{
        border:none;
        margin-top:20px;
        &.note-entry-editing-true{
            border: 1px solid #D9DEEB;
        }
        .btn-add-new{
            cursor: pointer;
            background-color: $gold;
            border-radius: 5px;
            color: white;
            padding:8px;
    
        }
    }

    

    .note-text{
        flex-grow: 1;
        width:100%;
        padding:0px 10px;
        padding-left:30px;
        line-height: 44px;
        border-right: 1px solid #D9DEEB;
 
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        height:44px; 
        position: relative;

        .linked-product-index{
           position: absolute;
           top:13px;
           left:8px;
        }

        &.note-text-open{
            height: auto;
            padding: 0px;

            textarea{
                margin:0px;
                height:150px;
                border: none;
                border-bottom: 1px solid #D9DEEB;
                box-shadow: none;
            }
        }

    }

    .linked-product-index{
        background-color: #555;
        color: white;
        border-radius: 200px;
        width:16px;
        height:16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;

        &.linked-catalogue{
            &.linked-catalogue-existing{
                background-color: $red;
            }
            &.linked-catalogue-proposed{
                background-color: $green;
            }
        }
    }

    .linked-product{
        padding:7px;
        line-height: 1rem;
        font-size: 0.8rem;
        -webkit-line-clamp: unset;
        overflow:  visible;
        height: 44px;
    }

    .note-controls{
        width:54px;
        
        display: flex;
        min-height: 44px;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 0;
        flex-direction: column;
        padding:7px 0px;

        button{
            color: #4A4A4A;
            font-size: 1.2rem;
            margin:5px 0px;
        }

        

        .div-pinning{

            display: flex;
            flex-direction: row;
            margin-top:4px;

            .a-toggle-pinning{
                &.pinning-true{
                    i{                 
                        animation: blinker 1s linear infinite;
                    }
                }
            }
            .linked-product-index{
           

                i{
                    display: none;
                }

                &:hover{
                    .val{
                        display: none;
                    }

                    i{
                        display: block;
                    }
                }
            }

        }
    }
}