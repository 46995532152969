.funeral-home-body {
  padding-top: 50px;
  padding-bottom: 50px;
  transition: max-height 0.15s;
  overflow-y: auto;
  min-height: $heightNormal;
}

.body-funeral-home-page {
}

body.scrolled {
  .funeral-home-body {
    min-height: $heightScrolled;
  }
}

table.data-table {
  thead {
    th {
      padding-bottom: 5px;
      padding-left: 10px;
      font-size: 11px;
      color: #476d8f;
    }
  }
  tbody tr {
    &:hover {
      background-color: #ffffff !important;
      transition: all 0.5s;
      box-shadow: 0 0 10px rgba($charcoal, 0.125);
      transform: scale(1.015);
      cursor: pointer;
      td {
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        i {
          color: $gold;
        }
      }
    }

    &.is-archived {
      opacity: 0.5;
    }

    &.hide.show-archived {
      display: table-row !important;
    }
  }

  td {
    &.centre {
      text-align: center;
    }
    &:first-child {
      i {
        margin-left: 5px;
      }
    }
    a {
      padding: 6px 10px;
      display: block;
      color: #4a4a4a;
    }

    input[type="checkbox"] {
      margin: 0;
    }

    &.title {
      font-weight: 900;
    }

    &.archiving {
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $gold;
        z-index: 10;
        content: "";
        border-radius: 0 4px 4px 0;
        opacity: 0.75;
      }

      &:after {
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        z-index: 20;
        content: "\f3f4";
        color: white;
        top: 50%;
        left: 50%;
        margin-top: -22.5px;
        margin-left: -15px;
        font-size: 30px;
        animation: fa-spin 1s linear infinite;
      }
    }
  }
}

button.view-toggle {
  padding: 8px;
  font-size: 20px;
  border: 2px solid $gold;
  border-radius: 3px;
  margin-left: 15px;
  color: $gold;
  &:hover {
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.1);
  }
}

p.meta {
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
  font-size: 12px;
  border-top: 1px solid rgba(white, 0.5);
  padding: 3px 0;
}

.dataset-archive {
  padding: 8px 15px;

  border-radius: 4px;
  color: $gold;

  i {
    font-size: 15px !important;
    margin-right: 4px;
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.cell-proposal-inner {
  .dataset-archive {
    background: rgba($gold, 0.75);
    color: white;
    &:hover {
      background: rgba($gold, 1);
    }
  }
}

.cell-funeral-home-overview,
.cell-funeral-home-notes {
  position: relative;

  .funeral-home-overview-route {
    margin-top: 20px;
    padding-top: 20px;
    font-size: 0.9rem;
    border-top: 1px solid $silver;

    > span {
      display: block;
      margin-bottom: 5px;
    }
  }

  &.cell-funeral-home-notes {
    padding-left: 30px;
    .funeral-home-overview-inner {
      padding-top: 48%;
    }
  }

  .funeral-home-overview-inner {
    width: 100%;
    height: 100%;
    padding-top: 100%;
    position: relative;
    display: block;
    color: $body;

    border: 1px solid #c5d0d9;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    .content {
      transition: opacity 0.25s;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 20px;

      overflow-y: auto;

      .proposal-overview {
        margin-bottom: 0;
        border-bottom: none;

        i {
          width: 15px;
        }

        > div:first-child {
          margin-top: 0;
        }

        .ul-notes {
          list-style: none;
          margin: 0px;
          padding: 0px;
          > li {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #ccc;

            .note-date {
              font-size: 0.8rem;
              text-align: right;
            }
            .note-content {
            }
          }
        }
      }
    }
  }

  &:hover {
    .hover {
      opacity: 1;
    }
    .content {
      opacity: 0.1;
    }
  }

  .hover {
    transition: opacity 0.25s;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #edf0f2;
    color: $body;
    text-align: center;
    opacity: 0;
    font-weight: bold;
    border-radius: 5px;

    i {
      margin-bottom: 10px;
    }
  }
}

.table-notes {
  margin-bottom: 40px;

  .th-date,
  .th-staff {
    width: 170px;
  }

  .th-icons {
    width: 40px;
  }
  .th-actioned {
    width: 80px;
  }

  border-collapse: collapse;

  thead {
    th {
      padding: 12px 8px;
    }
  }
  tbody {
    td {
      font-size: 0.9rem;
      padding: 10px;
      vertical-align: top;
    }

    .date-modified {
      color: #aaa;
      font-size: 0.8rem;
      margin-top: 5px;
    }

    .chk-actioned {
      color: #ddd;
      margin-right: 10px;

      &.on {
        color: $gold;
        transform: scale(1.15);
      }
    }

    .td-icons {
      i {
        transition: transform 0.25s, color 0.25s;
        cursor: pointer;

        &:hover {
          transform: scale(1.15);
          color: $gold;
        }
      }

      > div {
        display: inline-block;
      }
    }
  }
}

.ReactModal__Content {
  color: $body;

  .modal-clone & {
    @include breakpoint(large) {
      width: 600px;
      height: 400px;
      left: 50% !important;
      margin-left: -300px;
      top: 50% !important;
      margin-top: -200px;
    }

    button:not(.btn-close) {
      background-color: $gold;
      color: white;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;

      &.disabled-true {
        background-color: #eee;
        color: #ccc;
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $body;
    background-color: transparent;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($charcoal, 0.5);
    }
  }
}

.cell-creates {
  margin-bottom: 30px;
}
.cell-archive-home {
  margin-top: 30px;
}

.table-notes {
  .td-fh-note-edit {
    background-color: #f9f9f9;
    border: 1px solid #eee;
    padding: 50px;
    position: relative;

    .td-editing-close {
      position: absolute;
      top: 20px;
      right: 20px;

      i {
        cursor: pointer;
      }
    }
  }
}

.funeral-home-list-view {
  padding: 10px 20px 10px 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f2f4f6;
  border-radius: 3px;

  a {
    display: flex;
    align-items: center;

    div {
      align-items: center;
    }
  }

  &-name {
    font-size: 24px;
    font-weight: 900;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    color: #2e5a81;
  }

  &-group-name {
    font-size: 15px;
    font-weight: 350;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #1d1d1d;
  }

  &-contact-name {
    font-size: 15px;
    font-weight: 350;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #1d1d1d;
  }
}

.create-new-from-existing-button {
  margin-top: 1rem;
}

.allow-access-full-region-catalogue{
  margin-right: 8px;
}
