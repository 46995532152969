.catalogue {
    &-complete {
        margin-bottom: 3.25rem;
        
    }

    &-save-secondary {
        opacity: 0;
        text-align: right;
        transition: opacity 0.25s ease;

        &[data-show=true] {
            opacity: 1;
        }
    }

    &-actions {
        border-top: 1px solid $silver;
        padding-top: 2rem;
        margin-top: 2.75rem;
        
        .button {
            margin-bottom: 0;
        }
    }
    
    &-table {
        .button--reset {
            color: $gold;
        }

        &-custom-title {
            strong {
                margin-right: 1rem;
            }
        }
    }
}
