.change-log {
    margin: 1.5rem 0;

    td {
        padding: 0.25rem;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    &-date {
        width: 180px;
    }
}
