.nav-header-wrapper {
    background-color: #00427a;
    height: 100px;

    &-child {
        height: 100%;
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-image-logo {
            img {
                height: 60px;
            }
        }

        .nav-label-listing {
            ul {
                display: flex;
                list-style: none;
                align-items: center;
                margin: 0;
                li {
                    padding: 10px;
                    margin: 0;

                    a {
                        text-decoration: none;
                        color: white;
                        font-weight: bold;
                    }

                    .active {
                        color: #b89653;
                    }
                }
            }
        }
    }
}

.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
